$base-url: '/' !default;
$primary: #2f80ed;
$danger: #eb5757;
$warning: #f3d424;
$text_primary1: #000000;
$text_primary2: #333333;
$text_primary3: #4f4f4f;
$text_secondary: #828282;
$background_primary: #2f80ed19;
$background_secondary: #f2f2f2;
$font_weight_bold: 600;
$white: #fff;
$scrollbar: #c1c1c1;
