.filter-table {
  margin: 10px 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.flex-wrap-end {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.ant-picker {
  min-height: 40px;
}

.ant-select-selector {
  min-height: 40px;
  display: flex !important;
  align-items: center !important;
  .ant-select-selection-search-input {
    height: 35px !important;
  }
}
.ant-form-item {
  padding-top: 5px;
  margin-bottom: 5px;
}

.ant-avatar-image {
  box-shadow: #211e1e1f 2px 3px 10px 2px;
}

.ant-card-head-title {
  font-size: 16px;
}

.ant-descriptions {
  .ant-descriptions-row {
    > td {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 0;
      padding-bottom: 14px;
    }
    > th {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 0;
      padding-bottom: 14px;
    }
  }
}

.float-right {
  float: right;
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}

.ant-picker-cell-inner {
  padding: 0px !important;
}

.ant-picker-calendar-date-value {
  display: flex !important;
  justify-content: center !important;
  font-weight: 600 !important;
}

.ant-picker-calendar-date-content {
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.ant-picker-calendar-date-content::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.ant-upload-list-item::before {
  width: 100% !important;
  height: 100% !important;
  border-radius: 100% !important;
}

.ant-upload-list-text .ant-upload-list-item-container {
  .ant-upload-list-item-undefined {
    margin-top: -20px !important;
  }
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item:hover {
  background-color: transparent !important;
}

.ant-btn svg {
  margin-right: 0px !important;
}

.currency {
  .ant-select-selector {
    border-color: #d9d9d9 !important;
  }
}

.ant-picker-calendar-mode-switch {
  display: none !important;
}

.text-primary {
  color: #1677ff !important;
}
