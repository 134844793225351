@use '../../../assets/styles/variables';

.wrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.editButton {
  background-color: variables.$warning;
}
.deleteButton {
  background-color: variables.$danger;
  svg {
    fill: variables.$white;
  }
}
