body {
  padding: 0 0 30px 0;
  background-color: #fafafa;
  letter-spacing: -0.3px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.6;
  font-weight: 400;
  font-family: open sans, Helvetica, Arial, sans-serif;
  overflow: visible !important;
  width: 100% !important;
}

.ant-layout.ant-layout-has-sider {
  flex-direction: row;

  > .ant-layout {
    overflow-x: hidden;
  }

  > .ant-layout-content {
    overflow-x: hidden;
  }
}

.layout-dashboard {
  background: transparent;
  text-align: left;
  position: relative;
  overflow-x: hidden;

  .ant-layout-sider.sider-primary {
    width: 300px;
    position: fixed;
    left: 0;
    z-index: 99;
    height: 100vh;
    overflow: auto;
    margin: 0;
    padding: 10px 10px;

    .brand {
      img {
        height: 30px;
      }

      font-weight: 600;

      span {
        vertical-align: middle;
        margin-left: 3px;
      }
    }

    hr {
      margin: 18px 0;
      border: none;
      height: 1px;
      background-color: #f5f5f5;
    }

    .ant-layout-sider-children {
      width: 220px;
    }

    .ant-menu-inline {
      border: none;
      background: transparent;

      .ant-menu-item {
        margin: 0;
        overflow: visible;

        &:after {
          display: none;
        }
      }

      .ant-menu-submenu {
        margin: 0;
        overflow: visible;

        &:after {
          display: none;
        }
      }

      .ant-menu-item.menu-item-header {
        padding: 10px 16px !important;
        color: #8c8c8c;
        font-weight: 700;
        font-size: 13px;
        text-transform: uppercase;
        display: block;
      }
    }

    .ant-menu-item {
      height: auto;
      line-height: normal;

      .ant-menu-submenu-selected {
        background-color: transparent;
      }

      .ant-menu-submenu {
        &:active {
          background-color: transparent;
        }
      }

      .active {
        background-color: #fff;
        box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
        font-weight: 600;

        .icon {
          background-color: #1890ff;

          i {
            color: #fff;
          }
        }

        svg {
          path {
            fill: #fff;
          }
        }

        i {
          color: #fff;
        }
      }

      a {
        padding: 10px 5px;
        color: #141414;
        border-radius: 8px;
        display: flex;
        align-items: center;
        white-space: nowrap;

        &:hover {
          color: #141414;
        }

        i {
          color: #bfbfbf;
        }
      }

      .icon {
        display: inline-flex;
        width: 32px;
        height: 32px;
        background-color: #fff;
        box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
        border-radius: 6px;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        vertical-align: middle;
      }

      .sub-menu-icon {
        display: inline-flex;
        width: 24px;
        height: 24px;
        background-color: #fff;
        box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
        border-radius: 6px;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        vertical-align: middle;
        margin-left: 10px;
      }

      svg {
        path {
          fill: #bfbfbf;
        }
      }
    }

    .ant-menu-submenu {
      height: auto;
      line-height: normal;

      .ant-menu-submenu-selected {
        background-color: transparent;
      }

      .ant-menu-submenu {
        &:active {
          background-color: transparent;
        }
      }

      .active {
        background-color: #fff;
        box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
        font-weight: 600;

        .icon {
          background-color: #1890ff;
        }

        .sub-menu-icon {
          background-color: #1890ff;
        }

        svg {
          path {
            fill: #fff;
          }
        }
      }

      a {
        padding: 10px 5px;
        color: #141414;
        border-radius: 8px;
        display: flex;
        align-items: center;
        white-space: nowrap;

        &:hover {
          color: #141414;
        }

        .icon {
          i {
            color: #bfbfbf;
          }
        }

        .icon.active {
          i {
            color: white;
          }
        }
      }

      .icon {
        display: inline-flex;
        width: 32px;
        height: 32px;
        background-color: #fff;
        box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
        border-radius: 6px;
        justify-content: center;
        align-items: center;
        margin-right: 11px;
        vertical-align: middle;
      }

      .sub-menu-icon {
        display: inline-flex;
        width: 24px;
        height: 24px;
        background-color: #fff;
        box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
        border-radius: 6px;
        justify-content: center;
        align-items: center;
        margin-right: 11px;
        vertical-align: middle;
        margin-left: 24px;
      }

      svg {
        path {
          fill: #bfbfbf;
        }
      }
    }

    .ant-menu {
      .ant-menu-sub {
        .ant-menu-inline {
          font-weight: bold;
        }
      }
    }

    .ant-menu-item.ant-menu-item-selected {
      background-color: transparent;

      .active {
        background-color: #fff;
        box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
        font-weight: 600;

        .icon {
          background-color: #1890ff;
        }

        .sub-menu-icon {
          background-color: #1890ff;
        }

        svg {
          path {
            fill: #fff;
          }
        }

        i {
          color: #fff;
        }
      }
    }

    .ant-menu-item.ant-menu-item {
      &:active {
        background-color: transparent;
      }
    }

    .ant-menu-item.ant-menu-submenu-title {
      &:active {
        background-color: transparent;
      }
    }

    .ant-menu-submenu.ant-menu-item-selected {
      background-color: transparent;

      .active {
        background-color: #fff;
        box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
        font-weight: 600;

        .icon {
          background-color: #1890ff;
        }

        .sub-menu-icon {
          background-color: #1890ff;
        }

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    .ant-menu-submenu.ant-menu-item {
      &:active {
        background-color: transparent;
      }
    }

    .ant-menu-submenu.ant-menu-submenu-title {
      &:active {
        background-color: transparent;
      }
    }

    .footer-box {
      background-color: #fff;
      color: #141414;
      padding: 16px;
      border-radius: 8px;
      box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
      margin-bottom: 8px;

      .icon {
        display: inline-flex;
        width: 32px;
        height: 32px;
        box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
        border-radius: 6px;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        background-color: #1890ff;
      }

      h6 {
        font-weight: 600;
        font-size: 16px;
        color: #141414;
        margin-bottom: 0;
      }

      p {
        color: #8c8c8c;
        font-weight: 600;
      }

      button {
        margin: 0;
      }
    }

    .aside-footer {
      padding-top: 100px;
      padding-bottom: 33px;
    }
  }

  .ant-menu {
    background: transparent;
  }

  .ant-layout-sider.sider-primary.ant-layout-sider-primary {
    .ant-menu-item.ant-menu-item-selected {
      .active {
        .icon {
          background-color: #1890ff;
        }

        .sub-menu-icon {
          background-color: #1890ff;
        }
      }
    }

    .ant-menu-item {
      .active {
        .icon {
          background-color: #1890ff;
        }

        .sub-menu-icon {
          background-color: #1890ff;
        }
      }
    }

    .footer-box {
      background-color: #1890ff;
      color: #fff;
      box-shadow: none;

      .icon {
        background-color: #fff;
      }

      h6 {
        color: #fff;
      }

      p {
        color: #f5f5f5;
      }

      button {
        background-color: #fff;
        border-color: #f0f0f0;
        color: #141414;
      }
    }
  }

  .ant-layout-sider.sider-primary.ant-layout-sider-zero-width {
    overflow-x: hidden;
    width: 240px;
    margin: 0;
    padding: 33px 0;
  }

  .ant-layout {
    background: transparent;
    position: relative;
    min-height: 100vh;
    width: 100%;
    flex-shrink: 0;
  }

  .ant-layout-header {
    background: transparent;
    height: auto;
    padding: 16px;
    margin: 10px 4px;
    line-height: inherit;
    border-radius: 12px;
    transition: 0.2s;
  }

  .ant-breadcrumb {
    > span {
      .ant-breadcrumb-link {
        a {
          color: #8c8c8c;
        }
      }

      &:last-child {
        .ant-breadcrumb-link {
          color: #141414;
        }
      }
    }
  }

  .ant-page-header-heading {
    margin-top: 0;

    .ant-page-header-heading-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      margin-top: 4px;
    }
  }

  .header-control {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 10px;

    .ant-btn-link {
      height: auto;
      padding: 0 7px;
      margin: 0;
      box-shadow: none;
      color: #8c8c8c;
      line-height: normal;
    }

    svg {
      width: 20px;
      height: 20px;
      vertical-align: middle;

      path {
        fill: #141414;
      }
    }

    .btn-sign-in {
      height: auto;
      padding: 0 7px;
      margin: 0;
      box-shadow: none;
      color: #8c8c8c;

      svg {
        margin-right: 4px;
      }

      span {
        font-weight: 600;
        vertical-align: middle;
        display: none;
      }
    }

    .header-search {
      width: 213px;
      margin: 0 7px 0 0;
      border-radius: 6px;
      height: 40px;
      border: 1px solid #d9d9d9;

      svg {
        path {
          fill: #8c8c8c;
        }
      }

      .ant-input-suffix {
        .ant-input-search-icon.anticon-loading {
          display: block;
        }
      }

      input.ant-input {
        height: auto;
      }
    }

    .anticon.anticon-search {
      svg {
        width: 16px;
        height: 16px;
      }
    }

    .sidebar-toggler {
      display: block;
    }
  }

  .settings-drawer {
    .drawer-content {
      h6 {
        font-weight: 600;
        color: #141414;
      }
    }
  }

  .ant-layout-footer {
    background: transparent;
    margin: 0 20px 20px 20px;
    padding: 0;
  }

  .ant-layout-content {
    padding: 0;
    margin: 0 20px 0;
  }

  .ant-card {
    border-radius: 12px;
    box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  }

  .ant-affix {
    .ant-layout-header {
      background: #fff;
      box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
      margin: 10px 20px;
      z-index: 10;
    }
  }
}

.menu-item-header {
  &:hover {
    color: inherit;
  }
}

.layout-dashboard.has-sidebar {
  .ant-layout-sider.sider-primary {
    .aside-footer {
      display: block;
    }
  }
}

.ant-btn-sm {
  height: 34px;
  font-size: 12px;
  padding: 0 15px;
  border-radius: 4px;
}

.ant-radio-group-small {
  .ant-radio-button-wrapper {
    height: 34px;
    font-size: 12px;
    padding: 0 15px;
  }
}

.ant-btn {
  font-weight: 600;
  font-size: 12px;
  height: 40px;
  padding: 0 15px;
  line-height: initial;
  box-shadow: 0 2px 4px rgb(0 0 0 / 7%);
  border-radius: 6px;

  svg {
    margin-right: 5px;
  }

  > svg {
    vertical-align: middle;

    + {
      span {
        vertical-align: middle;
      }
    }
  }

  > span {
    + {
      .anticon {
        margin-right: 8px;
        margin-left: 0;
      }
    }
  }
}

.ant-radio-group {
  .ant-radio-button-wrapper {
    font-weight: 600;
    font-size: 12px;
    height: 40px;
    padding: 0 15px;
    line-height: 40px;
  }
}

.ant-menu-inline.ant-menu-root {
  padding: 20px 0;
  .ant-menu-item {
    display: block;
    padding: 0;
    margin: 0;
    height: fit-content;

    > .ant-menu-title-content {
      flex: auto;
      min-width: 0;
      overflow: visible;
      text-overflow: ellipsis;
    }
  }

  .ant-menu-submenu-title {
    display: block;
    padding: 0 !important;
    margin: 0;
    height: fit-content;

    > .ant-menu-title-content {
      flex: auto;
      min-width: 0;
      overflow: visible;
      text-overflow: ellipsis;
    }
  }
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown {
  background: #fff;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  border-radius: 12px;
  min-width: 276px;
  padding: 16px 8px;

  ul.ant-dropdown-menu-items {
    margin: 0;
    padding: 0;
  }

  .ant-list-item {
    padding: 5px 16px;
    margin-bottom: 8px;
  }

  .ant-avatar {
    width: 36px;
    height: 36px;
    background-color: #fafafa;
    box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-avatar-string {
      top: 6px;

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  .ant-list-item-meta-title {
    line-height: normal;
  }

  .ant-list-item-meta-description {
    svg {
      width: 14px;
      height: 14px;
      fill: #8c8c8c;
      vertical-align: middle;
      margin-right: 4px;

      path {
        fill: #8c8c8c;
      }
    }

    span {
      vertical-align: middle;
    }
  }
}

.ant-dropdown.ant-dropdown-placement-bottomRight {
  min-width: inherit !important;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdownt {
  .ant-list-item-meta-description {
    color: #8c8c8c;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
  }
}

.h3 {
  font-weight: 700;
  color: #141414;
}

.h4 {
  font-weight: 700;
  color: #141414;
}

h3 {
  font-weight: 700;
  color: #141414;
}

h4 {
  font-weight: 700;
  color: #141414;
}

.ant-list-item-meta-title {
  > a {
    color: rgba(0, 0, 0, 0.65);
    transition: all 0.3s;
  }

  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 22px;
}

.settings-drawer {
  .drawer-content {
    > hr {
      margin: 18px 0;
      border: none;
      height: 1px;
      background-color: #f5f5f5;
    }

    > h6 {
      font-size: 20px;
      margin-bottom: 0;
    }

    p {
      color: #8c8c8c;
    }

    .sidebar-color {
      h6 {
        margin-bottom: 8px;
      }
    }
  }
}

p {
  font-size: 14px;
}

.h6 {
  font-size: 16px;
}

h6 {
  font-size: 16px;
}

.drawer-sidebar {
  .ant-drawer-body {
    padding: 0;

    aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary {
      margin: 0;
    }
  }
}

.site-layout-background {
  background: transparent;
}

header.ant-layout-header.subheader {
  background: transparent;
}

.sidemenu {
  margin-top: 20px;
  margin-left: 20px;
}

.logo {
  img {
    width: 32px;
    margin-right: 10px;
  }

  margin-bottom: 15px;

  span {
    font-size: 16px;
    font-weight: 600;
  }
}

hr.horizontal.dark {
  background-image: linear-gradient(90deg, transparent, rgb(0 0 0 / 12%), transparent);
  border: 0;
}

hr.horizontal {
  background-color: transparent;
}

hr {
  &:not([size]) {
    height: 1px;
  }
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav {
  background: transparent;

  li {
    padding: 10px 16px !important;
    height: auto;
    line-height: normal;
    margin: 0;
    border: 0;
    font-size: 16px;
    color: #141414;

    &::after {
      display: none;
    }

    span.anticon {
      width: 32px;
      height: 32px;
      line-height: 32px;
      background: #fff;
      box-shadow: 0 4px 6px #0000001f;
      border-radius: 6px;
      font-size: 16px;
    }

    &:active {
      background: transparent;
    }

    svg {
      path {
        color: #bfbfbf;
      }
    }
  }

  li.ant-menu-item-selected {
    box-shadow: 0 20px 27px #0000000d;
    border-radius: 8px;
    background: #fff;
    border: 0;
    font-weight: 600;

    span.anticon {
      background: #1890ff;
      color: #fff;
    }

    path {
      color: #fff;
    }
  }
}

.divder {
  display: table;
  width: 100%;
  padding: 10px 15px;
  color: #8c8c8c;
  font-weight: 600;
  margin: 15px 0;
}

.ant-card.ant-card-bordered.documentation-card {
  border: 0;
  box-shadow: 0 20px 27px #0000000d;
  border-radius: 8px;
  background: #fff;
}

.p-15 {
  padding: 15px;
}

.card-document {
  width: 32px;
  height: 32px;
  text-align: center;
  box-shadow: 0 4px 6px #0000001f;
  border-radius: 6px;
  font-size: 19px;
  background: #1890ff;
  color: #fff;
  direction: ltr;
  margin-bottom: 15px;
}

.documentation-card {
  h4 {
    margin: 0;
  }
}

.left-nav {
  margin-top: 15px;
}

.ant-layout {
  background: #fafafa;
}

.pageheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rightside-top {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;

  span.ant-input-affix-wrapper {
    border-radius: 4px;

    span.ant-input-prefix {
      color: #c0c0c0;
    }
  }

  .ant-input {
    height: 30px;
  }

  .signin {
    display: inline-flex;

    svg {
      margin-right: 5px;
    }
  }
}

.signin {
  white-space: nowrap;
  margin: 0 20px;

  span.ant-avatar {
    margin-right: 9px;
  }

  h1 {
    font-size: 48px;
    font-weight: 700;
    color: #141414;
  }

  h5 {
    font-weight: 400;
    color: #8c8c8c;
    font-size: 20px;
    white-space: normal;
  }

  .ant-form-item-control-input {
    button.ant-switch {
      margin-right: 5px;
    }
  }
}

.shadow-lg {
  box-shadow: 0 8px 26px -4px hsla(0, 0%, 8%, 0.15), 0 8px 9px -5px hsla(0, 0%, 8%, 0.06) !important;
}

.ant-menu-inline {
  border: 0;
}

.ant-menu-vertical {
  border: 0;
}

.ant-menu-vertical-left {
  border: 0;
}

.header-top {
  h4 {
    margin: 0;
    font-size: 20px;
  }

  .subtitle {
    display: block;
    font-weight: normal;
    color: #8c8c8c;
    font-size: 14px;
  }

  margin-top: -10px;
  border-bottom: 1px solid rgb(140 140 140 / 7%);
  padding-bottom: 15px;
}

.ant-drawer.ant-drawer-right.ant-drawer-open.colorside {
  button.ant-drawer-close {
    color: #000;
  }
}

.setting {
  button.ant-btn {
    padding: 0;
    line-height: normal;
    background: transparent;
    border: 0;
    box-shadow: none;
  }
}

button.ant-btn.bell {
  padding: 0;
  border: 0;
  background: transparent;
  box-shadow: none;
  position: relative;

  strong {
    position: absolute;
    top: -1px;
    display: inline-block;
    background: #f5222d;
    border-radius: 100%;
    text-align: center;
    line-height: normal;
    font-weight: normal;
    color: #fff;
    right: -9px;
    width: 18px;
    height: 18px;
  }
}

.ant-dropdown-menu.ant-dropdown-menu-split.list-notifaction {
  width: 336px;
  border-radius: 0.75rem;
}

.list-notifaction {
  ul {
    padding: 5px 10px;
    margin: 0;

    li {
      display: block;
      width: 100%;
      clear: both;
      font-weight: 400;
      color: #67748e;
      text-align: inherit;
      white-space: nowrap;
      background-color: transparent;
      border: 0;
      padding: 0;

      a {
        display: block;
        padding: 10px;
        border-radius: 8px;

        &:hover {
          color: #252f40;
          background-color: #e9ecef;
        }

        span.ant-avatar-string {
          line-height: normal;
        }
      }
    }

    span.ant-avatar.ant-avatar-image {
      width: 48px;
      height: 48px;
      border-radius: 0.75rem;
    }
  }

  .ant-list-item-meta-title {
    font-weight: bold;
    color: #141414;
  }

  .ant-avatar.ant-avatar-circle {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.bnb2 {
  color: #52c41a;
  font-weight: 700;
}

.ant-card.criclebox {
  box-shadow: 0 20px 27px #0000000d;
  border-radius: 12px;

  .project-ant {
    padding-left: 24px;
    padding-right: 24px;
  }

  table {
    th {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }
}

.number {
  span {
    font-weight: 600;
    color: #8c8c8c;
    font-size: 14px;
  }

  h3 {
    font-weight: 700;
    margin-bottom: 0;
    font-size: 30px;

    small {
      font-weight: 600;
      font-size: 14px;
    }
  }
}

.icon-box {
  width: 48px;
  height: 48px;
  text-align: center;
  background: #1890ff;
  color: #fff;
  border-radius: 0.5rem;
  margin-left: auto;
  line-height: 55px;

  span {
    color: #fff;
    font-size: 24px;
  }
}

.listpage {
  padding-top: 15px;
}

.bar-chart {
  background: transparent linear-gradient(62deg, #00369e 0%, #005cfd 53%, #a18dff 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0 4px 6px #0000001f;
  border-radius: 8px;

  line.apexcharts-xaxis-tick {
    stroke: transparent;
  }
}

.chart-vistior {
  margin-top: 30px;

  h5 {
    margin: 0;
    font-weight: 700;
  }
}

.ant-typography.lastweek {
  color: #8c8c8c;
  font-weight: 600;
}

.chart-visitor-count {
  h4 {
    margin: 0;
    font-weight: 700;
  }

  span {
    color: rgba(0, 0, 0, 0.65);
    font-weight: 600;
  }
}

.linechart {
  h5 {
    font-weight: 700;
    margin: 0;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sales {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      &:first-child {
        span.anticon {
          color: #b37feb;
        }
      }

      &:last-child {
        span.anticon {
          color: #1890ff;
        }
      }

      color: #8c8c8c;
      margin-bottom: 3px;
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
    }
  }
}

.project-ant {
  h4 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #141414;
  }

  h5 {
    margin: 0;
    color: #141414;
    font-weight: 700;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
}

span.blue {
  margin-left: 5px;
  color: #1890ff;
  font-weight: 600;
  margin-left: 5px;
  color: #1890ff;
  font-weight: 600;
}

.antd-pro-pages-dashboard-analysis-style-salesExtra {
  a {
    display: inline-block;
    padding: 7px 10px;
    color: #595959;
    border: 1px solid #d9d9d9;
    text-align: center;
    border-right: 1px solid transparent;

    &:last-child {
      border-right: 1px solid #d9d9d9;
      border-radius: 0 5px 5px 0;
    }

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:hover {
      color: #1890ff;
      border: 1px solid #1890ff;
    }
  }

  a.active {
    color: #1890ff;
    border: 1px solid #1890ff;
  }
}

.ant-list-box {
  th {
    text-align: left;
    border-bottom: 1px solid #0000000d;
    padding: 8px 0;
    color: #8c8c8c;
    font-size: 12px;
    font-weight: 600;
  }

  td {
    text-align: left;
    border-bottom: 1px solid #0000000d;
    padding: 16px 25px;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 600;
  }

  h5 {
    font-size: 14px;
  }

  table {
    tr {
      &:hover {
        td {
          background: #fafafa;
        }
      }
    }
  }
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.d-flex {
  display: flex !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center !important;
}

.flex-column {
  flex-direction: column !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.tootip-img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid #fff;
  position: relative;
  z-index: 2;
  cursor: pointer;

  &:hover {
    z-index: 3;
    cursor: pointer;
  }
}

.avatar-group {
  .tootip-img {
    + {
      .tootip-img {
        margin-left: -10px;
      }
    }
  }
}

.title-box {
  h5 {
    margin: 0;
  }
}

.text-xs {
  color: #8c8c8c;
}

.percent-progress {
  span.ant-progress-text {
    display: flex;
    margin-top: -27px;
    color: #8c8c8c;
    position: relative;
    margin-left: 0;
  }

  .ant-progress-status-exception {
    .ant-progress-text {
      color: #ff4d4f;
    }
  }

  .ant-progress-bg {
    height: 3px !important;
  }
}

.ant-full-box {
  width: 100%;
  border: 1px dashed #d9d9d9;
  padding: 10px;
  height: auto;
  color: #141414;
  border-radius: 10px;
}

.uploadfile {
  margin-top: 15px;
  padding-left: 24px;
  padding-right: 24px;

  .ant-upload.ant-upload-select {
    display: block;
  }
}

.ant-timeline-item-tail {
  left: 7px;
}

.timeline-box {
  h5 {
    margin-bottom: 0;
    font-weight: 700;
  }
}

.timelinelist {
  h5 {
    font-size: 14px;
    font-weight: 600;
  }

  span {
    color: #8c8c8c;
    font-size: 12px;
  }
}

.ant-muse {
  span {
    color: #8c8c8c;
    font-weight: 600;
  }

  h5 {
    margin: 0 0 15px !important;
    font-size: 20px;
  }
}

.bg-gradient-primary {
  background-image: linear-gradient(310deg, #2152ff, #21d4fd);
}

img {
  max-width: 100%;
}

.border10 {
  border-radius: 10px;
}

.py-4 {
  padding: 20px;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.icon-move-right {
  .anticon-right {
    color: #40a9ff;
    margin-left: 2px;
  }

  color: #1890ff;
  display: block;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.gradent {
  background: url('../images/info-card-2.jpg') no-repeat center top;
  background-size: cover;
  padding: 15px;
  border-radius: 15px;
  color: #fff;

  h5 {
    color: #fff;
  }

  .ant-typography {
    color: #fff;
    font-size: 20px;
  }

  .icon-move-right {
    color: #fff;

    .anticon-right {
      color: #fff;
    }
  }
}

.copyright {
  color: #8c8c8c;
  margin-top: 7px;

  .anticon-heart {
    color: #f5222d;
    margin: 0 5px;
  }

  a {
    margin: 0 5px;
    color: #141414;
    font-weight: 700;
  }
}

.footer-menu {
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    li {
      list-style: none;

      a {
        padding: 0.5rem 1rem;
        display: block;
        color: #8c8c8c;
      }
    }
  }
}

span.ismobile {
  display: none;
  margin-right: 15px;
  cursor: pointer;
}

.sidebar-color {
  padding: 1.5rem 0;
}

.theme-color {
  button {
    margin-right: 8px;
    border: 0;
    width: 20px !important;
    height: 20px !important;
    padding: 0;
    box-shadow: none;
    visibility: visible !important;
    line-height: 20px !important;

    &:focus {
      box-shadow: none;
      border-color: transparent;
    }

    span {
      opacity: 0;
    }
  }
}

.mb-2 {
  margin-bottom: 25px;
}

.sidebarnav-color {
  button {
    width: 100%;
    margin-right: 10px;
    box-shadow: 0 3px 5px -1px rgb(0 0 0 / 9%), 0 2px 3px -1px rgb(0 0 0 / 7%);
    height: 40px;

    &:last-child {
      margin-right: 0;
    }
  }

  h5 {
    margin: 0;
  }

  span.ant-typography {
    color: #8c8c8c;
  }

  .trans {
    margin-top: 1rem;
    display: flex;
  }
}

.ant-docment {
  button {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
  }
}

.viewstar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;

  a {
    display: inline-block;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 16px;
    border-radius: 0em 0.25em 0.25em 0em;
    border: 1px solid #ddddde;
    border-left: 0;
    color: #141414;

    &:first-child {
      color: #24292e;
      background-color: #eff3f6;
      border-color: #cfd3d6;
      background-image: linear-gradient(180deg, #fafbfc, #eff3f6 90%);
      border-radius: 0.25em 0 0 0.25em;
      border-left: 1px solid #ddddde;
    }
  }
}

.social {
  text-align: center;
  margin-top: 25px;

  button {
    margin: 0 5px;
    display: inline-flex;
    align-items: center;
  }

  svg {
    path {
      fill: #fff;
    }

    width: 15px;
    height: 15px;
  }
}

.ant-thank {
  text-align: center;
  margin-top: 25px;
}

.setting-drwer {
  background: #fff;
  border-radius: 50%;
  bottom: 42px;
  right: 30px;
  font-size: 1.25rem;
  z-index: 990;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 16%);
  width: 52px;
  height: 52px;
  text-align: center;
  line-height: 60px;
  position: fixed;
  cursor: pointer;
}

.aside-footer {
  padding-top: 100px;
}

.ant-card-body {
  padding: 16px;
}

.cardbody {
  .ant-card-body {
    padding-left: 0;
    padding-right: 0;
  }
}

.ant-card.tablespace {
  .ant-card-body {
    padding: 0;
    margin: 0 20px;
  }
}

.tablespace {
  .ant-card-head-title {
    font-size: 20px;
    font-weight: 600;
  }

  overflow: hidden;
}

.ant-radio-button-wrapper {
  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }

  color: #595959;
}

.ant-avatar-group {
  .ant-avatar-square {
    border-radius: 4px;
  }

  align-items: center;
}

.shape-avatar {
  margin-right: 20px;

  img {
    border-radius: 4px;
  }
}

.avatar-info {
  h5 {
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 3px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: #8c8c8c;
    margin: 0;
  }
}

.ant-table-tbody {
  > tr {
    > td {
      h5 {
        font-size: 14px;
        font-weight: 600;
        color: #141414;
        margin-bottom: 0;
      }

      p {
        font-size: 14px;
        color: #8c8c8c;
        margin: 0;
      }

      padding: 16px 25px;
      white-space: nowrap;

      h6 {
        font-size: 14px;
        font-weight: 600;
        color: #141414;
      }
    }
  }
}

.tag-primary {
  border-radius: 6px;
  padding: 2px 12px;
  font-size: 14px;
  height: auto;
  line-height: 24px;
  font-weight: 600;
}

.ant-employed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  color: #8c8c8c;

  a {
    color: #8c8c8c;
    text-decoration: none;
    font-weight: 600;
  }
}

.tag-badge {
  border-radius: 6px;
  padding: 2px 12px;
  font-size: 14px;
  height: auto;
  line-height: 24px;
  font-weight: 600;
  background: #8c8c8c;
  color: #fff;
  outline: none;

  &:hover {
    background: #8c8c8c;
    color: #fff;
    border-color: #8c8c8c;
    opacity: 0.9;
  }
}

.mb-24 {
  margin-bottom: 24px;
}

.ant-table-thead {
  > tr {
    > th {
      color: #8c8c8c;
      font-weight: 700;
      background-color: transparent;
      font-size: 12px;
      padding: 16px 25px;
      white-space: nowrap;
    }
  }
}

.ant-border-space {
  th {
    &:before {
      display: none;
    }
  }
}

.ant-table.ant-table-middle {
  .ant-table-title {
    padding: 16px 25px;
  }

  .ant-table-footer {
    padding: 16px 25px;
  }

  .ant-table-thead {
    > tr {
      > th {
        padding: 16px 25px;
      }
    }
  }

  .ant-table-tbody {
    > tr {
      > td {
        padding: 16px 25px;
      }
    }
  }

  tfoot {
    > tr {
      > th {
        padding: 16px 25px;
      }

      > td {
        padding: 16px 25px;
      }
    }
  }
}

.avatar-sm {
  width: 25px;
}

.ant-progress-project {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ant-progress-outer {
    float: right;
  }

  .ant-progress-text {
    margin: 0 0 0 0;
    color: #8c8c8c;
    font-weight: 600;
  }

  .ant-progress {
    margin-right: 15px;
  }

  .ant-progress-bg {
    height: 3px !important;
  }
}

.semibold {
  font-weight: 600;
  color: #8c8c8c;
}

.text-sm {
  font-size: 12px;
  color: #8c8c8c;
  font-weight: 600;
}

.h-full {
  height: 100%;
}

.card-info-2 {
  .ant-card-body {
    height: 100%;
  }
}

.col-content {
  flex-grow: 1;
  padding: 24px;
  margin-right: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-content {
  p {
    font-size: 16px;
  }
}

.ant-layout-footer {
  padding: 0 50px;
}

.layout-default.ant-layout.layout-sign-up {
  header.ant-layout-header {
    background-color: transparent;
    color: #fff;
    margin: 0;
    padding: 0 20px;
    z-index: 1;
  }

  padding: 11px;

  .ant-layout-footer {
    ul {
      li {
        &:after {
          display: none;
        }
      }
    }
  }

  footer.ant-layout-footer {
    ul {
      li {
        a {
          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }

  .header-col.header-brand {
    h5 {
      color: #fff;
    }
  }

  .header-col.header-nav {
    a {
      color: #fff;
    }

    svg {
      path.fill-muted {
        fill: #fff;
      }
    }
  }
}

.layout-default {
  .ant-layout-header {
    display: flex;

    .header-brand {
      h5 {
        white-space: nowrap;
        color: #141414;
        margin: 0;
      }
    }

    .header-nav {
      a {
        svg {
          margin-right: 5px;
          vertical-align: middle;
        }

        span {
          vertical-align: middle;
        }

        color: #141414;
        padding: 6px 12px;
        font-weight: 400;
      }

      .ant-menu-horizontal {
        > .ant-menu-item {
          color: #8c8c8c;
          font-weight: 600;
          border: none;
          padding: 0;

          &:hover {
            color: #8c8c8c;
            font-weight: 600;
            border: none;
          }
        }

        > .ant-menu-item-active {
          color: #8c8c8c;
          font-weight: 600;
          border: none;
        }

        > .ant-menu-item-selected {
          color: #8c8c8c;
          font-weight: 600;
          border: none;
        }

        > .ant-menu-submenu {
          color: #8c8c8c;
          font-weight: 600;
          border: none;

          &:hover {
            color: #8c8c8c;
            font-weight: 600;
            border: none;
          }
        }

        > .ant-menu-submenu-active {
          color: #8c8c8c;
          font-weight: 600;
          border: none;
        }

        > .ant-menu-submenu-selected {
          color: #8c8c8c;
          font-weight: 600;
          border: none;
        }
      }
    }
  }

  .ant-layout-footer {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    background: transparent;
    text-align: center;

    .ant-menu-horizontal {
      border: none;
      line-height: 1.5;
      margin: 0 0 30px;
      background-color: transparent;
      font-size: 16px;
      text-align: center;
      display: block;

      > .ant-menu-item {
        color: #8c8c8c;
        border: none;

        &:hover {
          color: #8c8c8c;
          border: none;
          color: #262626;
        }
      }

      > .ant-menu-item-active {
        color: #8c8c8c;
        border: none;
        color: #262626;
      }

      > .ant-menu-item-selected {
        color: #8c8c8c;
        border: none;
        color: #262626;
      }

      > .ant-menu-submenu {
        color: #8c8c8c;
        border: none;

        &:hover {
          color: #8c8c8c;
          border: none;
          color: #262626;
        }
      }

      > .ant-menu-submenu-active {
        color: #8c8c8c;
        border: none;
        color: #262626;
      }

      > .ant-menu-submenu-selected {
        color: #8c8c8c;
        border: none;
        color: #262626;
      }
    }

    .menu-nav-social {
      a {
        svg {
          fill: #8c8c8c;
          vertical-align: middle;
        }

        &:hover {
          svg {
            fill: #1890ff;
          }
        }
      }
    }

    .copyright {
      a {
        color: inherit;
        font-weight: normal;
      }

      font-size: 16px;
      color: #8c8c8c;
    }
  }

  .ant-layout-content {
    padding-top: 40px;
  }

  .ant-layout-content.p-0 {
    padding: 0;
  }
}

.header-col.header-nav {
  margin-left: auto;
  width: 100%;
  margin-right: auto;
  color: #fff;
  font-weight: 600;

  .ant-menu-overflow {
    justify-content: center;
  }

  .ant-menu-horizontal {
    background: transparent;
    box-shadow: none;
    border: 0;
  }

  li {
    &:after {
      display: none;
    }
  }

  ul {
    li {
      color: #fff !important;

      img {
        margin-top: -2px;
        margin-right: 5px;
      }
    }
  }
}

.sign-up-header {
  height: 550px;
  margin-top: -62.8px;
  padding-top: 137.8px;
  border-radius: 12px;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  background-size: cover;
  background-position: 50%;
  text-align: center;
  background: url('../images/bg-signup.jpg') no-repeat center top;

  .content {
    padding-top: 40px;
    max-width: 480px;
    margin: auto;

    h1 {
      font-size: 48px;
      color: #fff;
    }
  }

  * {
    color: #fff;
  }
}

.header-col.header-brand {
  white-space: nowrap;

  h5 {
    margin: 0;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  }
}

.header-col.header-btn {
  button {
    height: 34px;
    padding: 0 16px;
    border-radius: 34px;
    line-height: 33px;
  }
}

.text-lg {
  font-size: 16px;
}

.card-signup {
  .sign-up-gateways {
    .ant-btn {
      margin-right: 10px;
      margin-left: 10px;
      height: 60px;
      width: 70px;
      box-shadow: none;
      border-radius: 6px;

      img {
        width: 20px;
      }
    }
  }

  width: 100%;
  max-width: 500px;
  margin: auto;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  border-radius: 12px;
  margin-top: -190px;
  margin-bottom: 20px;

  h5 {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #141414;
    margin: 0;
  }
}

.header-solid {
  .ant-card-head {
    border-bottom: 0;
    border-bottom: 0;
  }
}

.sign-up-gateways {
  text-align: center;
}

.m-25 {
  margin-bottom: 25px;
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.my-25 {
  margin-bottom: 25px;
  margin-top: 25px;
}

.mt-25 {
  margin-top: 25px;
}

.text-center {
  text-align: center;
}

.font-semibold {
  font-weight: 600;
}

.text-muted {
  color: #8c8c8c;
}

.ant-input {
  border-radius: 6px;
  font-weight: 600;
  color: #8c8c8c;
  height: 40px;
  border: 1px solid #d9d9d9;
}

.font-bold {
  font-weight: 700;
}

.text-dark {
  color: #141414;
}

.layout-sign-up {
  .ant-layout-footer {
    padding: 24px 50px;
  }
}

.layout-default.layout-signin {
  header.ant-layout-header {
    display: flex;
    position: relative;
    background-color: #fff;
    color: #141414;
    box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
    flex-direction: row;
    padding: 0 50px;
    height: 64px;
    line-height: 64px;
  }
}

.fill-muted {
  fill: #8c8c8c;
}

.sign-in {
  padding: 0 20px;
}

.font-regular {
  font-weight: 400;
}

.ant-row.ant-form-item.username {
  display: block;

  .ant-col.ant-form-item-label {
    .ant-form-item-required {
      &:before {
        display: none;
      }
    }

    font-size: 13px;
    font-weight: 600;
    color: #141414;
  }
}

.sign-img {
  img {
    width: 100%;
    max-width: 500px;
    margin: auto;
    display: block;
  }
}

.layout-signin {
  background: #fff;

  footer.ant-layout-footer {
    svg {
      width: 18px;
      height: 18px;
    }

    ul {
      li {
        &:after {
          display: none;
        }
      }
    }
  }
}

.row-col {
  .ant-row {
    margin-left: 0;
    margin-right: 0;
  }
}

.m-10 {
  margin-right: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mx-10 {
  margin-right: 10px !important;
}

small.redtext {
  color: red;
}

.full-width {
  path {
    fill: transparent;
  }
}

section.ant-layout.layout-content {
  .ant-layout-content.content-ant {
    padding: 0 !important;
  }
}

.card-credit {
  background-image: url('../images/info-card-3.jpg');
  background-position: 50%;
  background-size: cover;
  border: none;

  * {
    color: #fff;
  }

  .card-number {
    word-spacing: 10px;
    font-weight: 600;
    margin-bottom: 45px;
    font-size: 20px;
  }

  .ant-card-body {
    padding-top: 15px;
  }

  .card-footer {
    display: flex;
    align-items: flex-end;

    h6 {
      margin-bottom: 0;
      font-weight: 700;
    }

    p {
      margin-bottom: 0;
    }
  }

  .col-logo {
    img {
      max-width: 50px;
    }
  }
}

.ant-card-head-wrapper {
  min-height: 72px;
}

.m-auto {
  margin-left: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.m-30 {
  margin-right: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mx-30 {
  margin-right: 30px !important;
}

.width-100 {
  width: 100%;
}

.p-20 {
  padding: 0 20px 0 0;
}

.pt {
  padding-top: 0;
}

.widget-2 {
  .icon {
    width: 64px;
    height: 64px;
    margin: auto;
    background-color: #1890ff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    img {
      width: 30px;
    }
  }

  .ant-statistic-title {
    h6 {
      font-weight: 600;
    }

    p {
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 0;
    }

    text-align: center;
    padding: 16px;
  }

  .ant-statistic-content {
    font-family: open sans, Helvetica, Arial, sans-serif;
    font-weight: 600;
    font-size: 20px;
    padding: 12px 16px;
    border-top: 1px solid #f5f5f5;
    text-align: center;
  }

  .ant-card-body {
    padding: 0;
  }
}

.ant-row-flex-middle {
  align-items: center;
}

.payment-method-card.ant-card {
  box-shadow: none;
  word-spacing: 10px;

  img {
    width: 40px;
  }

  .ant-card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ant-btn {
    margin-right: -20px;
    margin-right: -20px;
  }

  h6 {
    font-weight: 600;
    margin-bottom: 0;
    color: #141414;
  }
}

.ant-btn.ant-btn-link.ant-edit-link {
  font-weight: 600;
  font-size: 12px;
  height: 40px;
  padding: 0 15px;
  box-shadow: none;

  img {
    width: 20px;
  }
}

.fill-gray-7 {
  fill: #8c8c8c !important;
}

.m-0 {
  margin: 0;
}

.ant-card.ant-card-bordered.payment-method-card {
  .ant-card-body {
    padding-top: 16px;
  }
}

.invoice-list {
  .ant-list-item {
    padding: 11px 0;
    border-bottom: 0;
  }

  .ant-list-item-meta-title {
    font-size: 14px;
    font-weight: 600;
    color: #141414;
  }

  .ant-list-item-meta-description {
    font-size: 12px;
    font-weight: 600;
    color: #8c8c8c;
  }

  .ant-list-item-action {
    .ant-btn {
      font-weight: 600;
      color: #141414;
      box-shadow: none;
    }
  }

  .amount {
    font-size: 14px;
    font-weight: 600;
    color: #8c8c8c;
  }
}

.transactions-list {
  .ant-list-item {
    padding: 11px 0;
    border-bottom: 0;
  }

  .ant-list-item-meta-title {
    font-size: 14px;
    font-weight: 600;
    color: #141414;
  }

  .ant-list-item-meta-description {
    font-size: 12px;
    font-weight: 600;
    color: #8c8c8c;
  }

  .ant-list-item-action {
    .ant-btn {
      font-weight: 600;
      color: #141414;
      box-shadow: none;
    }
  }

  .amount {
    font-size: 14px;
    font-weight: 600;
    color: #8c8c8c;
    font-size: 16px;
    font-weight: 700;
  }

  .ant-list-item-meta {
    align-items: center;
  }
}

.ant-invoice-card {
  .ant-card-body {
    padding-top: 0;
  }
}

.card-billing-info.ant-card {
  box-shadow: none;
  background-color: #fafafa;
  border: 1px solid #f5f5f5;

  .darkbtn {
    color: #8c8c8c;
  }

  .ant-descriptions-header {
    margin-bottom: 0;
  }
}

.ant-descriptions {
  .ant-descriptions-title {
    font-size: 14px;
    font-weight: 600;
    color: #141414;
    margin-bottom: 14px;
    font-size: 14px;
    font-weight: 600;
    color: #141414;
    margin-bottom: 14px;
  }

  .ant-descriptions-row {
    > td {
      padding-bottom: 6px;
    }

    > th {
      padding-bottom: 6px;
    }
  }

  .ant-descriptions-item-label {
    font-size: 14px;
    font-weight: 600;
    color: #8c8c8c;
  }

  .ant-descriptions-item-content {
    font-size: 13px;
    font-weight: 600;
    color: #141414;
  }
}

.fill-danger {
  fill: #ff4d4f;
}

.col-action {
  display: flex;
}

.card-billing-info {
  .col-action {
    button {
      height: 34px;
      font-size: 12px;
      padding: 0 15px;
      box-shadow: none;
    }
  }
}

.card-header-date {
  margin-bottom: 0;
  font-weight: 700;
  color: #8c8c8c;

  > * {
    vertical-align: middle;
  }

  svg {
    margin-right: 5px;
  }
}

.text-light-danger {
  background: #fde3cf;
  color: #f56a00;
}

.text-fill {
  color: #52c41a;
  background: #edf9e7;
}

.text-danger {
  color: #f5222d;
}

.text-success {
  color: #52c41a;
}

.text-warning {
  background: rgb(255, 252, 231);
  color: #fadb14;
}

.text-warning-b {
  font-size: 14px;
  font-weight: 600;
  color: #fadb14;
}

.ant-card.ant-list-yes {
  .ant-list-header {
    border: 0;

    h6 {
      font-size: 12px;
      font-weight: 600;
      color: #8c8c8c;
      margin: 6px 0;
    }
  }
}

.ant-list.ant-list-split.transactions-list.ant-newest {
  .ant-list-header {
    padding-top: 0;
  }
}

.profile-nav-bg {
  height: 300px;
  margin-top: -137.8px;
  border-radius: 12px;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  background-size: cover;
  background-position: 50%;
}

section.ant-layout.ant-layout-has-sider.layout-dashboard.layout-profile {
  span.ant-page-header-heading-title {
    color: #fff;
  }

  span.ant-breadcrumb-separator {
    color: #fff;
  }

  span.ant-breadcrumb-link {
    color: #fff;
  }

  .header-control {
    svg {
      path {
        fill: #fff;
      }
    }

    span.ant-input-affix-wrapper.header-search {
      .anticon-search {
        svg {
          path {
            fill: #8c8c8c;
          }
        }
      }
    }
  }

  .btn-sign-in {
    color: #fff;
  }
}

.layout-profile {
  .ant-layout-header {
    margin: 10px 20px;
  }

  .ant-card-head {
    padding: 0 16px;
  }
}

.card-profile-head {
  .ant-card-head {
    border-bottom: 0;
    padding: 0 16px;

    .ant-avatar {
      box-shadow: 0 5px 10px rgb(0 0 0 / 12%);
      border-radius: 8px;
      margin: 0;
    }

    .avatar-info {
      margin-left: 16px;

      h4 {
        font-size: 24px;
        font-size: 24px;
      }
    }
  }

  margin: -53px 0 24px;

  .ant-radio-group {
    .ant-radio-button-wrapper {
      height: auto;
      font-weight: 700;
    }
  }
}

.ant-card-head-title {
  p {
    font-weight: 600;
    color: #8c8c8c;
    margin-bottom: 0;
    font-weight: 600;
    color: #8c8c8c;
    margin-bottom: 0;
  }

  h6 {
    margin-bottom: 0;
  }
}

.settings-list {
  list-style: none;
  padding: 0;

  > li {
    &:first-child {
      padding-top: 0;
    }

    padding: 12px 0;

    h6 {
      font-size: 12px;
      color: #8c8c8c;
      margin: 0;
      font-weight: 700;
    }

    span {
      margin-left: 16px;
      color: #141414;
    }
  }
}

.ant-btn-link {
  box-shadow: none;
}

.card-profile-information {
  hr {
    opacity: 0.2;
  }

  .ant-descriptions {
    .ant-descriptions-row {
      > td {
        padding-bottom: 16px;
      }

      > th {
        padding-bottom: 16px;
      }
    }
  }
}

.p-5 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.px-5 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.m-5 {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mx-5 {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.conversations-list {
  .ant-list-item-meta-description {
    font-size: 12px;
    font-weight: 600;
    color: #8c8c8c;
  }

  .ant-avatar {
    border-radius: 10.5px;
    box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
  }

  .ant-list-item {
    padding: 14px 0;
  }
}

.card-project.ant-card {
  box-shadow: none;
  border: none;

  .ant-card-cover {
    img {
      border-radius: 8px;
      box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
    }
  }

  .card-tag {
    font-weight: 600;
    color: #8c8c8c;
    margin-bottom: 5px;
    font-weight: 600;
    color: #8c8c8c;
    margin-bottom: 5px;
  }

  p {
    font-weight: 600;
    color: #8c8c8c;
    margin-bottom: 5px;
    font-weight: 600;
    color: #8c8c8c;
    margin-bottom: 5px;
    margin-bottom: 0;
  }

  h5 {
    font-weight: 600;
    font-size: 20px;
  }

  .ant-card-body {
    padding: 16px 0 0;
  }

  .card-footer {
    margin-top: 22px;
  }
}

.avatar-chips {
  .ant-avatar-image {
    border: 1px solid #fff;

    &:hover {
      z-index: 1;
    }
  }

  .ant-space-item {
    &:hover {
      z-index: 1;
    }
  }
}

.projects-uploader {
  height: 100%;
  border-radius: 8px;
  border-color: #d9d9d9;

  .ant-upload {
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 8px;
    padding: 8px;
  }

  .ant-upload-list.ant-upload-list-picture-card {
    height: 100%;
  }

  .ant-upload.ant-upload-select-picture-card {
    background-color: transparent;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.text-right {
  text-align: right;
}

.layout-dashboard-rtl {
  background: transparent;
  text-align: right;
  direction: rtl;
  position: relative;
  overflow-x: hidden;

  .ant-layout-sider.sider-primary {
    .ant-menu-item {
      .icon {
        margin-left: 0px;
        margin-right: 0;
      }
    }

    .ant-menu-submenu {
      .icon {
        margin-left: 0px;
        margin-right: 0;
      }
    }
  }

  .ant-timeline-item-tail {
    right: 6.5px;
    left: auto;
  }

  .ant-timeline-item-content {
    margin: 0 33px 0 0;
  }

  .ant-radio-button-wrapper {
    &:first-child {
      border-right-width: 1px;
      border-radius: 0 4px 4px 0;
    }
  }

  .ant-list-box {
    td {
      text-align: right;
    }
  }

  .ant-space-horizontal {
    direction: ltr;
  }

  .ant-btn {
    > .anticon {
      + {
        span {
          margin-right: 8px;
          margin-left: 0;
        }
      }
    }
  }

  .setting-drwer {
    left: 30px;
    right: auto;
  }

  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
    text-align: right;
  }

  .avatar-group.mt-2 {
    direction: ltr;
  }

  .icon-box {
    margin-left: 0;
    margin-right: auto;
  }

  .m-10 {
    margin-left: 10px !important;
  }

  .mr-10 {
    margin-left: 10px !important;
  }

  .mx-10 {
    margin-left: 10px !important;
  }

  .ant-progress-show-info {
    .ant-progress-outer {
      margin-right: 0;
      padding-right: 0;
    }
  }

  .ant-badge-count {
    left: 0;
    right: auto;
    transform: translate(-50%, -50%);
  }

  .ant-badge-dot {
    left: 0;
    right: auto;
    transform: translate(-50%, -50%);
  }

  .ant-badge {
    .ant-scroll-number-custom-component {
      left: 0;
      right: auto;
      transform: translate(-50%, -50%);
    }
  }

  .header-control {
    .btn-sign-in {
      svg {
        margin-left: 4px;
        margin-right: 0;
      }
    }
  }

  .ant-drawer-left {
    .ant-drawer-content-wrapper {
      left: 0;
      direction: rtl;
      left: 0;
      direction: rtl;
    }
  }
}

.ant-timeline-item-head {
  width: 15px;
  height: 15px;
  border-width: 3px;
}

.ant-drawer.drawer-sidebar.drawer-sidebar-rtl {
  aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary {
    left: auto;
    right: 0;
    direction: rtl;
  }
}

.layout-dashboard.layout-dashboard-rtl {
  .header-control {
    span.ant-badge {
      margin: 0 7px;
    }
  }

  span.ant-input-prefix {
    margin-right: 0;
    margin-left: 4px;
  }
}

.settings-drawer.settings-drawer-rtl {
  left: 0;
  direction: rtl;

  button.ant-drawer-close {
    left: 0;
    right: auto;
    margin-right: 0;
    margin-left: var(--scroll-bar);
    padding-left: calc(20px - var(--scroll-bar));
  }

  .sidebarnav-color {
    button {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  .viewstar {
    a {
      &:first-child {
        border-radius: 0em 0.25em 0.25em 0;
        border-right: 1px solid #ddddde;
      }

      border-radius: 0.25em 0 0 0.25em;
      border: 1px solid #ddddde;
      border-right: 0;
    }
  }

  .social {
    svg {
      margin-right: 0;
      margin-left: 5px;
    }
  }
}

aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary.active-route {
  a.active {
    background: transparent;
    box-shadow: none;
  }
}

.uploadfile.pb-15 {
  padding-bottom: 16px;
}

.ant-row.ant-form-item.aligin-center {
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.65);
  }
}

.uploadfile.shadow-none {
  .ant-upload.ant-upload-select.ant-upload-select-text {
    .ant-btn.ant-full-box {
      box-shadow: none;
    }
  }
}

.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.65);

  &:active {
    color: #096dd9;
    background-color: #fff;
    border-color: #096dd9;
  }
}

button.ant-btn.ant-btn-dashed.ant-full-box {
  svg {
    width: 16px;
    height: 16px;
  }
}

.ant-btn-dashed.active {
  color: #096dd9;
  background-color: #fff;
  border-color: #096dd9;
}

@media (min-width: 992px) {
  .layout-dashboard {
    overflow: auto;
  }
}

@media (max-width: 992px) {
  .footer-menu {
    ul {
      justify-content: center;
    }
  }
  footer.ant-layout-footer {
    padding: 0 15px;
    text-align: center;
  }
  .project-ant {
    display: block;
  }
  .ant-filtertabs {
    text-align: right;
  }
  .rightside-top {
    span.ant-input-affix-wrapper {
      width: 200px;
    }

    justify-content: flex-end;
  }
  header.ant-layout-header.subheader {
    padding: 0 24px 0 0;
  }
  main.ant-layout-content.content-ant {
    padding-left: 0 !important;
  }
  .ant-row {
    margin-left: -12px;
    margin-right: -12px;
  }
  span.ismobile {
    display: inline-block;
  }
  .bar-chart {
    width: 100% !important;
    max-width: 100%;
    width: 100% !important;
  }
  .full-width {
    max-width: 100% !important;
  }
  .icon-move-right {
    margin-bottom: 15px;
  }
  .pageheader {
    display: block;
  }
  .ant-cret {
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }
  }
}

@media (min-width: 1200px) {
  .ant-col.col-img {
    img {
      height: 196px;
    }
  }
}

@media (max-width: 1024px) {
  .bar-chart {
    max-width: 100%;
  }
  .full-width {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .sign-img {
    img {
      margin: 0;
    }
  }
  .card-signup {
    margin-bottom: 120px;

    .sign-up-gateways {
      .ant-btn {
        width: 100px;
      }
    }
  }
}

:where(.css-dev-only-do-not-override-12upa3x).ant-table-wrapper
.ant-table-pagination.ant-pagination {
  margin: 16px;
}

.ant-input-group {
  .ant-input-affix-wrapper {
    .ant-input {
      height: 30px;
    }
  }

  .ant-input-group-addon {
    .ant-input-search-button {
      height: 40px;
    }
  }
}

.ant-upload-list-item {
  border-radius: 50% !important;
  padding: 0px !important;
  width: 100% !important;
}

.ant-upload-list-item-thumbnail {
  img {
    border-radius: 50% !important;
    object-fit: none !important;
  }
}

.ant-upload-list-item-thumbnail-object-fit-contain img {
  object-fit: contain !important;
}

.qrCodeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@import './global.scss';
@import './animation.scss';

.ant-modal-title {
  text-transform: capitalize;
}

div[role='alert']::first-letter {
  text-transform: capitalize;
}

.ant-form-item .ant-form-item-label > label {
  font-weight: 600;
}