@media (min-width: 992px) {
  .layout-dashboard {
    .ant-layout-footer {
      margin: 0 0 20px 20px;
    }
    .ant-layout-sider.sider-primary {
      margin: 20px 0 0 20px;
      padding: 13px 20px;
      height: calc(100vh - 20px);
    }
    .ant-layout {
      width: auto;
      flex-shrink: 1;
      margin-left: 300px;
    }
    .header-control {
      .sidebar-toggler {
        display: none;
      }
    }
  }
  .card-billing-info.ant-card {
    .ant-card-body {
      display: flex;
    }
  }
  .layout-dashboard-rtl {
    overflow: auto;
    .ant-layout-sider.sider-primary {
      margin: 20px 20px 0 0;
      height: calc(100vh - 20px);
      right: 0;
      left: auto;
      right: 0;
      left: auto;
    }
    .ant-layout {
      margin-right: 300px;
      margin-left: 0;
    }
    .ant-layout-footer {
      margin: 0 20px 20px 0;
    }
  }
}
@media (min-width: 768px) {
  .layout-dashboard {
    .ant-layout-header {
      margin: 10px 20px;
    }
    .header-control {
      margin-top: 0;
      .header-search {
        margin: 0 7px;
      }
      .btn-sign-in {
        span {
          display: inline;
        }
      }
    }
  }
  .profile-nav-bg {
    margin-top: -87.8px;
  }
  .card-profile-head {
    margin: -53px 24px 24px;
  }
}
@media (max-width: 768px) {
  .layout-dashboard.layout-dashboard-rtl {
    .header-control {
      justify-content: flex-end;
    }
  }
}
